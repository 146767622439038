import { clientService } from "@/services/ClientService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    clientData: [],
    isAddingClient: false,
    editingClient: false,
    editClientRequest: {
        loading: false,
    },
    addClientRequest: {
        loading: false,
    },
    getClientsRequest: {
        loading: false
    },
};

const actions = {
    getClientData({commit}){
        commit('setGetClientRequest',{loading: true});
        clientService.getClientData()
            .then((response) => {
                commit('setGetClientRequest', {loading: false});
                commit('updateClient', response.data);
            })
            .catch((error) => {
               commit('setGetClientRequest', {loading: false, message: error});
            });
    },
    async updateClient({commit, state}, {client}){
        commit('setEditClientRequest', {loading: true});

        clientService.updateClient(client)
            .then((response) => {
                toast.success(i18n.global.t("toast.successEdit"));
                commit('setEditClientRequest', {loading: false});
                const updatedClient = state.clientData.map(x => (x.clientDataId ===  client.clientDataId ? client : x));
                commit('updateClient', updatedClient);
                commit('setEditingClient', null);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.errorEdit"));
                commit('setEditClientRequest', {loading: false, message: error});
            });
    },
    async addClient({commit, state}, {client}){
        commit('setAddClientRequest', {loading: true});
        clientService.addClient(client)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                const updatedClient = state.clientData;
                updatedClient.push(response.data);
                commit('setAddClientRequest', {loading: false});
                commit('updateClient', updatedClient);
                commit('setIsAddingClient', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setAddClientRequest', {loading: false, message: error});
            })
    }
}

const mutations = {
    setEditingClient(state, editingClient){
        state.editingClient = editingClient;
        state.isAddingClient = false;
    },
    setIsAddingClient(state, isAddingClient){
        state.isAddingClient = isAddingClient;
        state.editingClient = null;
    },
    setGetClientRequest(state, {loading, message}){
        state.getClientRequest = {
            loading: loading,
            message: message
        }
    },
    setAddClientRequest(state, {loading, message}){
        state.addClientRequest = {
            loading: loading,
            message: message
        }
    },
    setEditClientRequest(state, {loading, message}){
        state.editClientRequest = {
            loading: loading,
            message: message
        }
    },
    updateClient(state, newList){
        state.clientData = newList;
    }
}

export const client = {
    namespaced: true,
    state,
    actions,
    mutations
};