<template>
  <div class="main">
    <div class="form-input-container">
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="promoCode"
                type="text"
                :hint="$t('promoPageStrings.promoCode')"
            />
            <p v-if="errors.promoCode" class="error-message">{{ errors.promoCode }}</p>
          </div>
        </div>
        <div class="col-6">
          <DesignInput
              v-model:customValue="discountAmount"
              type="number"
              :hint="$t('promoPageStrings.discountAmount')"
          />
          <p v-if="errors.discountAmount" class="error-message">{{ errors.discountAmount }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="maxUses"
                type="number"
                :hint="$t('promoPageStrings.maxUses')"
            />
            <p v-if="errors.maxUses" class="error-message">{{ errors.maxUses }}</p>
          </div>
        </div>
        <div class="col-6">
          <DesignInput
              v-model:customValue="usedCount"
              type="number"
              :hint="$t('promoPageStrings.usedCount')"
          />
          <p v-if="errors.usedCount" class="error-message">{{ errors.usedCount }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput v-model:customValue="startDate" :hint="$t('promoPageStrings.startDate')" :type="'date'"/>
            <p v-if="errors.startDate" class="error-message">{{ errors.startDate }}</p>
          </div>
        </div>

        <div class="col-6">
          <DesignInput v-model:customValue="endDate" :hint="$t('promoPageStrings.endDate')" :type="'date'"/>
          <p v-if="errors.endDate" class="error-message">{{ errors.endDate }}</p>
        </div>
      </div>

    </div>
    <p v-if="editPromoRequest.message || addPromoRequest.message" class="text-status error">
      {{ editPromoRequest.message || addPromoRequest.message }}</p>
    <div class="button-container row">
      <design-button class="button col-lg-12" @customClick="cancel()" type="secondary"> {{ $t('generic.cancel') }}</design-button>
      <design-button class="button col-lg-12 p-2" @customClick="submitPromo()"
                     :loading="editPromoRequest.loading || addPromoRequest.loading"> {{ $t('generic.save') }}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";

export default {
  name: 'EditPromoDialog',
  components: {
    DesignInput,
    DesignButton
  },
  methods: {
    ...mapMutations('promo', ['setEditingPromo']),
    ...mapActions('promo', ['updatePromo', 'addPromo', 'getPromo']),
    ...mapState("promo", ["promo"]),
    validateFields() {
      this.errors = {}; // Reset errors

      const promoCodeRegex = /^[a-zA-Z0-9]+$/; // Only alphanumeric characters

      if (!this.promoCode) {
        this.errors.promoCode = this.$t('promoPageStrings.erPromoCode')
      } else if (!promoCodeRegex.test(this.promoCode)) {
        this.errors.promoCode = this.$t('promoPageStrings.erPromoCode2')
      }

      if (!this.maxUses || this.maxUses < 1) {
        this.errors.maxUses = this.$t('promoPageStrings.erMaxUses');
      }

      if (!this.discountAmount || this.discountAmount < 1) {
        this.errors.discountAmount = this.$t('promoPageStrings.erDiscountAmount');
      }

      if (!this.usedCount && this.usedCount < 0) {
        this.errors.usedCount = this.$t('promoPageStrings.erUsedCount');
      }

      if (!this.startDate) {
        this.errors.startDate = this.$t('promoPageStrings.erStartDate');
      }

      if (!this.endDate) {
        this.errors.endDate = this.$t('promoPageStrings.erEndDate');
      }

      return Object.keys(this.errors).length === 0; // Return true if no errors
    },


    submitPromo() {
      if (!this.validateFields()) {
        return;
      }

      const parseDate = (date) => {
        return date ? new Date(date).getTime() : null;
      };

      const promoData = {
        promoCodeId: this.editingPromo?.promoCodeId || null,
        promoCode: this.promoCode,
        discountAmount: this.discountAmount,
        startDate: parseDate(this.startDate),
        endDate: parseDate(this.endDate),
        maxUses: this.maxUses,
        usedCount: this.usedCount
      };

      const promoRequestObject = {
        promo: promoData,
        orgId: this.user.orgId
      };

      if (this.editingPromo && this.editingPromo.promoCodeId) {
        this.updatePromo(promoRequestObject);
      } else {
        this.addPromo(promoRequestObject);
      }
    },

    cancel() {
      this.setEditingPromo(null);
    },
  },
  computed: {
    ...mapState('promo', ['editingPromo', 'editPromoRequest', 'addPromoRequest', 'getPromoRequest', 'promo']),
    ...mapState("account", ["user"])
  },
  data() {
    return {
      promoCode: "",
      discountAmount: "",
      startDate: "",
      endDate: "",
      maxUses: 1,
      usedCount: 0,
      errors: {}
    }
  },
  mounted() {
    if (this.editingPromo) {
      this.promoCode = this.editingPromo.promoCode || "";
      this.discountAmount = this.editingPromo.discountAmount || 0;
      this.startDate = this.editingPromo.startDate
          ? new Date(this.editingPromo.startDate).toISOString().split("T")[0]
          : "";
      this.endDate = this.editingPromo.endDate
          ? new Date(this.editingPromo.endDate).toISOString().split("T")[0]
          : "";
      this.maxUses = this.editingPromo.maxUses || 1;
      this.usedCount = this.editingPromo.usedCount || 0;
    }
  },
  watch: {
    maxChoicePaidPreselect(newVal, oldVal) {
      if (newVal >= 0) {
        this.maxChoicePaid = newVal;
      }
    },
    maxChoiceFreePreselect(newVal, oldVal) {
      if (newVal >= 0) {
        this.maxChoiceFree = newVal;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: auto;
  padding: 20px 20px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;
  padding-bottom: 70px;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.profile-data-container {
  padding: 0 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;

  }


}

.color-picker {
  height: 50px !important;
}

@media (max-width: $screen-md) {

  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }
}


.custom-check {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  input {
    margin-right: $default-edge-spacing;
  }
}
.dropdown-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-image {
  width: 30px; /* Taille du carré */
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.dropdown-text {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>