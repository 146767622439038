import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const clientService = {
    getClientData,
    updateClient,
    addClient
}

function getClientData() {
    return apiClient.get(`/client_data?all=true`, {
        headers: authHeader()}
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function updateClient(client) {
    return apiClient.patch(`/client_data`, client,{
        headers: authHeader()}
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function addClient(client) {
    return apiClient.post(`/client_data`,client,{
        headers: authHeader()}
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}