import { promoService } from "@/services/PromoService";
import { toast } from "vue3-toastify";
import { i18n } from "./../lang";

const state = {
    promo: [],
    isAddingPromo: false,
    editingPromo: null,
    editPromoRequest : {
        loading: false
    },
    addPromoRequest : {
        loading: false
    },
    getPromoRequest : {
        loading: false
    },
    deletePromoRequest: {
        loading: false
    }
}

const actions = {
    getPromo({commit}){
        commit('setGetPromoRequest', {loading: true})
        promoService.getPromo()
            .then((response) => {
                commit('setGetPromoRequest', {loading: false})
                commit('updatePromo', response.data);
            })
            .catch((error) => commit('setGetPromoRequest', {loading: false, message: error}))
    },
    async updatePromo({commit, state}, {promo, orgId}) {
        commit('setEditPromoRequest', {loading: true});

        try {
            const response = await promoService.updatePromo(promo);
            toast.success(i18n.global.t("toast.updateSuccess"));

            const updatedPromoList = state.promo.map(item =>
                item.promoCodeId === promo.promoCodeId ? response : item
            );

            commit('updatePromo', updatedPromoList);
            commit('setEditingPromo', null);
        } catch (error) {
            toast.error(i18n.global.t("toast.updateError"));
            commit('setEditPromoRequest', {loading: false, message: error.message || error});
        }

        commit('setEditPromoRequest', {loading: false});
    },
    async addPromo({commit, state}, {promo, file, orgId}){
        commit('setAddGroupsRequest', {loading: true});
        promoService.addPromo(promo)
            .then((response) => {
                toast.success(i18n.global.t("toast.createSuccess"));
                const updatedPromo = state.promo;
                updatedPromo.push(response.data)
                commit('setAddGroupsRequest', {loading: false});
                commit('updatePromo', updatedPromo);
                commit('setIsAddingPromo', false);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.createError"));
                commit('setAddGroupsRequest', {loading: false, message: error})
            })
    },
    deletePromo({commit, state}, promo) {
        promoService.deletePromo(promo)
            .then(() => {
                toast.success(i18n.global.t("toast.deleteSuccess"));
                const updatedPromoList = state.promo.filter(element => element.promoCodeId !== promo.promoCodeId);
                commit('updatePromo', updatedPromoList);
            })
            .catch((error) => {
                toast.error(i18n.global.t("toast.deleteError"));
                commit('setDeletePromoRequest', { loading: false, message: error });
            });
    }
}

const mutations = {
    setEditingPromo(state, editingPromo){
        state.editingPromo = editingPromo;
        state.isAddingPromo = false;
    },
    setGetPromoRequest(state, {loading, message}){
        state.getPromoRequest = {
            loading: loading,
            message: message
        }
    },
    setAddGroupsRequest(state, {loading, message}){
        state.addPromoRequest = {
            loading: loading,
            message: message
        }
    },
    setEditPromoRequest(state, {loading, message}){
        state.editPromoRequest = {
            loading: loading,
            message: message
        }
    },
    setDeletePromoRequest(state, {loading, message}){
        state.deletePromoRequest = {
            loading: loading,
            message: message
        }
    },
    updatePromo(state, newList){
        state.promo = newList;
    },
    setIsAddingPromo(state, isAddingPromo){
        state.isAddingPromo = isAddingPromo;
        state.editingPromo = null;
    }
}

export const promo = {
    namespaced: true,
    state,
    actions,
    mutations,
}