<template>
    <div class="input-container">
        <div class="hint-container">
            <p class="input-hint"> {{ hint }} </p>
            <span class="tooltip-container" v-if="tooltipText" v-tooltip.left="tooltipText">
              <inline-svg
                  class="icon-info"
                  :src="require('@/assets/icons/info.svg')"></inline-svg>
          </span>
        </div>
        <div class="input-field-container">

            <div v-if="attachedFilePreview && Array.isArray(attachedFilePreview)">
                <div class="image-preview-container">
                    <!-- Show the first image as the preview -->
                    <img
                        class="image-attach-preview"
                        :src="attachedFilePreview[0]"
                        alt="Preview"
                    />
                    <!-- Overlay with the count of images -->
                    <div class="image-count-overlay">
                        +{{ attachedFilePreview.length }}
                    </div>
                </div>
            </div>

            <!-- Show single preview if only one file is selected -->
          <img
              class="image-attach-preview"
              v-if="attachedFilePreview"
              :src="attachedFilePreview"
              alt="Preview"
          />

            <input
                ref="customInput"
                :class="error ? 'error' : ''"
                :type="type"
                :value="customValue"
                :disabled="disabled"
                @input="$emit('update:customValue', $event.target.value)"/>
            <inline-svg
                v-if="fileAttachEnabled"
                class="trailing-icon"
                @click="onAttachFileClicked"
                :src="require('@/assets/icons/paperclip.svg')">

            </inline-svg>

            <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="image/*"
                :multiple="multiple"
                @change="onFilePicked"/>

        </div>
    </div>
</template>

<script>
import Tooltip from 'primevue/tooltip';
import { toast } from "vue3-toastify";
import {watch} from "vue";

export default {
    name: "DesignInput",
    directives: {
        'tooltip': Tooltip
    },
    emits: [
        'update:customValue',
        'update:attachedFile'
    ],
    props: {
        'type': {
            type: String,
            default: ""
        },
        'min': {
            type: Number,
            default: 1
        },
        'max': {
            type: Number,
            default: 100
        },
        'hint': {
            type: String,
            default: ""
        },
        'customValue': {
            type: [String, Number],
            default: ""
        },
        'error': {
            type: Boolean,
            default: false
        },
        'disabled': {
            type: Boolean,
            default: false
        },
        'tooltipText': {
            type: String,
            default: ""
        },
        'fileAttachEnabled': {
            type: Boolean,
            default: false
        },
        'fileAttachLimitMb': {
            type: Number,
            default: 2
        },
        'multiple': {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            attachedFilePreview: null,
        }
    },
    watch: {
        customValue(newValue) {
          if (newValue && typeof newValue === 'string') {
            if (this.isValidImageUrl(newValue)) {
              this.attachedFilePreview = newValue;
            } else {
              this.attachedFilePreview = null;
            }
          }
        }
    },
    methods: {
        isValidImageUrl(url) {
          return /^https?:\/\/.+\.(jpg|jpeg|png|gif|webp|svg|asd)$/.test(url);
        },
        onAttachFileClicked() {
            this.$refs.fileInput.click();
        },
        onFilePicked(event) {
            // Get the list of selected files
            const files = event.target.files;
            if (!files || files.length === 0) {
                this.attachedFilePreview = null;
                this.$emit('update:attachedFile', null);
                return;
            }

            // Array to store file previews and validated files
            const filePreviews = [];
            const validFiles = [];

            for (const file of files) {
                // Check file size
                if (file.size > this.fileAttachLimitMb * 1024 * 1024) {
                    toast.error(this.$t('generic.fileSizeError', { size: this.fileAttachLimitMb }));
                    return; // Skip this file if it exceeds the size limit
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                    filePreviews.push(e.target.result);
                };
                reader.readAsDataURL(file);

                // Add the file to the valid files array
                validFiles.push(file);
            }

            // Wait for all previews to be generated
            Promise.all(
                Array.from(files).map(
                    (file) =>
                        new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onload = () => resolve(reader.result);
                            reader.readAsDataURL(file);
                        })
                )
            ).then((previews) => {
                if (previews.length === 1) {
                    // If there's only one preview, set a single value
                    this.attachedFilePreview = previews[0];
                    this.$emit('update:attachedFile', validFiles[0]);
                } else {
                    // Otherwise, set and emit the arrays
                    this.attachedFilePreview = previews;
                    this.$emit('update:attachedFile', validFiles);
                }
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.input-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .hint-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 8px !important;

        .input-hint {
            padding: 0 !important;
            font-size: 12px;
            font-weight: normal;
            color: $color-secondary-text;
            opacity: 0.8;
            margin: 0 !important;
        }

        .icon-info {
            stroke: rgba($color-secondary-text, 0.5);
            width: 12px;
            height: 12px;
        }

        .tooltip-container {
            line-height: 0 !important;
        }
    }

    .input-field-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .image-attach-preview {
            width: 50px;
            height: 50px;
            margin-right: 10px;
            border-radius: $radius-small;
        }

        .trailing-icon {
            color: $color-secondary-text !important;
            position: absolute;
            right: $default-edge-spacing-l;
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }

    .image-count-overlay {
        position: absolute;
        top: 0;
        width: 50px;
        height: 50px;
        border-radius: $radius-small;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        padding: 4px 8px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    input {
        width: 100%;
        padding: 10px 40px 10px 20px;

        &.error {
            outline: 1px solid rgba($color-pastel-red, .3);;
            box-shadow: 0px 0px 15px rgba($color-pastel-red, .3);
        }

        &:disabled {
            opacity: 0.3;
        }
    }
}
</style>