import {imageService} from "@/services/ImageService";
import {toast} from "vue3-toastify";
import {i18n} from "@/lang";

const state = {
    images: [],
};


const getters = {
    getAllImages(state) {
        return state.images;
    }
};

const mutations = {
    SET_IMAGE(state, images) {
        state.images = [...images];
    }
};

const actions ={
    async fetchImages({ commit }) {
        try {
            const response = await imageService.getAllImages();
            commit("SET_IMAGE", response.data);

        }catch(error){
            console.error("Error fetching image list:", error);
        }
    },

    async deleteImage({ dispatch }, imageId) {
        try {
            await imageService.deleteImage(imageId);
            toast.success(i18n.global.t("toast.deleteSuccess"));
            await dispatch("fetchImages");
        }catch(error){
            toast.error(i18n.global.t("toast.deleteError"));
            console.error("Error deleting image:", error);
        }
    },

    async addImage({ dispatch }, { orgId, extraAttachedFiles }) {
        try {
            let successCount = 0;
            let failedCount = 0;
            if (extraAttachedFiles && extraAttachedFiles.length > 0) {
                for (let i = 0; i < extraAttachedFiles.length; i++) {
                    try {
                        await imageService.uploadImage(extraAttachedFiles[i], orgId);
                        successCount++;
                    } catch (error) {
                        console.error("Error uploading file:", error);
                        failedCount++;
                    }
                }
            }

            if (successCount > 0) {
                toast.success(`${successCount} images added successfully`);
            }

            if (failedCount > 0) {
                toast.error(`${failedCount} images could not be added`);
            }
            await dispatch("fetchImages");
        } catch (error) {
            console.error("Error in addImage action:", error);
            toast.error("Error during upload");
        }
    }

}

export const images = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
