<template>
  <div>
    <popup-view :isVisible="currentImage" @close="!currentImage">
      <AddImageDialog
          @cancelEditAction="currentImage = false"/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="!deleteOpenDialog">
      <confirm-delete-dialog :kitchenMessage ="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>
    <page-header
        @action-click="openAddingDialog"
        :actionText="$t('imagePageStrings.addImage')"
        actionIconName="plus.svg"
        iconName="picture2.svg"
        :headerTitle="$t('imagePageStrings.name')"
    />

    <div class="flex justify-content-end" style="margin-top: 15px">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText v-model="filters.global.value" placeholder="Keyword Search" class="search-input" />
      </span>
    </div>

    <div class="main" style="margin-left: -10px; margin-right: -10px; margin-top:-20px !important">
      <div class="image-page-container">
        <div class="row justify-content-start">
          <div class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="image in validFilteredImages" :key="image.imageId">
            <div class="image-item card" >
              <div class="image-overlay">
                <img  :src="image.imageUrl" :alt="'Image ' + image.imageId" width="100%" height="150px" class="card-img-top" @error="handleImageError(image)" @click="openModal(image.imageUrl)" />
                <div class="overlay-icons">
                  <button class="icon-btn" @click="openDeleteDialog(image.imageId)">
                    <inline-svg :src="require('@/assets/icons/trash.svg')" />
                  </button>
                </div>
              </div>
              <div class="image-info">
                <p>{{image.imageUrl.replace("https://posbel-images-upload.s3.amazonaws.com/", "")}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="modalImageUrl" alt="Preview" class="modal-image" />
        <button class="modal-close" @click="closeModal">✖</button>
      </div>
    </div>
  </div>
</template>

<script >
import { defineComponent, watch, ref, computed } from "vue";
import PageHeader from "@/views/components/PageHeader.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import InputText from "primevue/inputtext";
import PopupView from "@/views/components/PopupView.vue";
import ConfirmDeleteDialog from "@/views/dialogs/ConfirmDeleteDialog.vue";
import AddImageDialog from "@/views/dialogs/AddImageDialog.vue";

export default defineComponent({
  name: "ImagePage",
  components: {
    AddImageDialog,
    ConfirmDeleteDialog, PopupView,
    PageHeader,
    InputText,
  },

  data() {
    return {
      filters: ref({
        global: { value: "" } || { value: null }
      }),
      deleteOpenDialog: false,
      currentSelect: {},
      modalOpen: false,
      modalImageUrl: "",
      currentImage: false
    };
  },

  computed: {
    ...mapGetters("images", ["getAllImages"]),
    ...mapState("account", ["user"]),
    images() {
      console.log("Images mises à jour dans computed:", this.getAllImages);
      return [...this.getAllImages]; // Force Vue à détecter le changement
    },
    filteredImages() {
      return this.images.filter(image => !this.filters.global.value || image.imageUrl.replace("https://posbel-images-upload.s3.amazonaws.com/", "").toLowerCase().includes(this.filters.global.value.toLowerCase()));
    },
    validFilteredImages() {
      return this.filteredImages.filter(image => image.imageUrl && image.imageUrl.trim() !== "");
    }
  },

  methods: {
    ...mapActions("images", ["fetchImages", "deleteImage","addImage"]),
    openDeleteDialog(id) {
      this.deleteOpenDialog = true;
      this.currentSelect = id;
    },
    openAddingDialog() {
      this.currentImage = true;
    },
    handleImageError(image) {
      image.imageUrl = "";
    },
    submitDelete() {
      this.deleteImage(this.currentSelect);
      this.deleteOpenDialog = false;
    },
    openModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.fetchImages();
    }
  },
  mounted() {
    this.fetchImages();
  }
});
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fond assombri */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgba(119, 119, 119, 0.24);
  border-radius: 10px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modal-image {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 10px;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(119, 119, 119, 0.75);
  color: white;
  border: none;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
}



.image-info{
  padding: 8px;
  font-size: 14px
}

.image-page-container {
  margin-top: 25px;
  padding: 0 !important;
  box-sizing: border-box;
}
.search-input {
  height: 40px;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
}


.image-item {
  overflow: hidden;
  border-radius: 8px;
  padding: 0px !important;
  background-color:rgba(129, 137, 201, 0.18);
  margin: 10px !important;
  position: relative;
}

.image-overlay {
  position: relative;
  display: inline-block;
  width: 100%;
}

.image-overlay img  {
  width: 100%;
  height: 150px;
  padding: 0 !important;
  object-fit: cover;
  border-radius: 8px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.overlay-icons {
  position: absolute;
  top: 25px;
  right: -15px;
  transform: translate(-50%, -50%);
  display: flex;
  transition: opacity 0.3s ease-in-out;
}

.icon-btn {
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

</style>