<template>
  <div class="main">
    <div class="form-input-container">
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="name"
                type="text"
                :hint="$t('clientStrings.name')"
            />
            <p v-if="errors.name" class="error-message">{{ errors.name }}</p>
          </div>
        </div>
        <div class="col-6">
          <DesignInput
              v-model:customValue="phoneNumber"
              type="text"
              :hint="$t('clientStrings.phone')"
          />
          <p v-if="errors.phoneNumber" class="error-message">{{ errors.phoneNumber }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <DesignInput
              v-model:customValue="email"
              type="text"
              :hint="$t('clientStrings.email')"
          />
          <p v-if="errors.email" class="error-message">{{ errors.email }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <DesignInput
                v-model:customValue="street"
                type="text"
                :hint="$t('clientStrings.street')"
            />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="number"
                type="text"
                :hint="$t('clientStrings.number')"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="box"
                type="text"
                :hint="$t('clientStrings.box')"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="city"
                type="text"
                :hint="$t('clientStrings.city')"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="zipCode"
                type="text"
                :hint="$t('clientStrings.zipCode')"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mr-1 customDrop mt-2">
            <p class="input-hint">{{$t('clientStrings.business')}}</p>
            <Dropdown  v-model="business"  :options="choice" optionLabel="name" class="dropInput w-100"  />
          </div>
        </div>
        <div class="col-6">
          <div class="mr-1">
            <DesignInput
                v-model:customValue="vat"
                type="text"
                :hint="$t('clientStrings.vat')"
            />
            <p v-if="errors.vat" class="error-message">{{ errors.vat }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <DesignInput
              v-model:customValue="extraInfo"
              type="text"
              :hint="$t('clientStrings.extra')"
          />
          <p v-if="errors.extraInfo" class="error-message">{{ errors.extraInfo }}</p>
        </div>
      </div>

    </div>
    <p v-if="editClientRequest.message || addClientRequest.message" class="text-status error">
      {{ editClientRequest.message || addClientRequest.message }}</p>
    <div class="button-container row">
      <design-button class="button col-lg-12" @customClick="cancel()" type="secondary"> {{ $t('generic.cancel') }}</design-button>
      <design-button class="button col-lg-12 p-2" @customClick="submitClient()"
                     :loading="editClientRequest.loading || addClientRequest.loading"> {{ $t('generic.save') }}
      </design-button>
    </div>
  </div>
</template>

<script>
import DesignInput from '../components/DesignInput.vue';
import DesignButton from '../components/DesignButton.vue';
import Dropdown from 'primevue/dropdown';
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";

export default {
  name: 'EditClientDialog',
  components: {
    DesignInput,
    DesignButton,
    Dropdown
  },
  methods: {
    ...mapMutations('client', ['setEditingClient']),
    ...mapActions('client', ['updateClient', 'addClient', 'getClientData']),
    ...mapState("client", ["clientData"]),
    validateFields() {
      this.errors = {};

      if (!this.name) {
        this.errors.name = this.$t('clientStrings.erName')
      }

      if (!this.phoneNumber) {
        this.errors.phoneNumber = this.$t('clientStrings.erPhone');
      } else if (!/^\+?\d{10,15}$/.test(this.phoneNumber)) {
        this.errors.phoneNumber = this.$t('clientStrings.erPhone2');
      }

      if (!this.email) {
        this.errors.email = this.$t('clientStrings.erMail')
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        this.errors.email = this.$t('clientStrings.erMail2');
      }

      if (this.business && this.business.name === this.$t("generic.yes")) {
        if (!this.vat) {
          this.errors.vat = this.$t('clientStrings.erVat');
        } else if (!/^[A-Z]{2}\d{9,12}$/i.test(this.vat)) {
          this.errors.vat = this.$t('clientStrings.erVat2');
        }
      }

      return Object.keys(this.errors).length === 0;
    },

    submitClient() {
      if (!this.validateFields()) {
        return;
      }

      let choice = this.business?.name === this.$t("generic.yes");

      if (this.editingClient){
        const client = {
          clientDataId: this.editingClient.clientDataId,
          name: this.name,
          phoneNumber: this.phoneNumber,
          street: this.street,
          city: this.city,
          extraInfo: this.extraInfo,
          zipCode: this.zipCode,
          number: this.number,
          business: choice,
          box: this.box,
          vat: this.vat,
          email: this.email,
          dataSource: this.editingClient.dataSource,
        };
        this.updateClient({client});
      }else{
        const client = {
          name: this.name,
          phoneNumber: this.phoneNumber,
          street: this.street,
          city: this.city,
          zipCode: this.zipCode,
          number: this.number,
          extraInfo: this.extraInfo,
          business: choice,
          box: this.box,
          vat: this.vat,
          email: this.email,
        };
        this.addClient({client});
      }
    },
    cancel() {
      this.setEditingClient(null);
    },
  },
  computed: {
    ...mapState('client', ['editingClient', 'editClientRequest', 'addClientRequest', 'getClientsRequest', 'clientData']),
  },
  data() {
    return {
      name: "",
      phoneNumber: "",
      street: "",
      city: "",
      extraInfo: "",
      zipCode: "",
      number: "",
      business: "",
      box: "",
      vat: "",
      email: "",
      errors: {},
      choice: [
        { name: this.$t("generic.yes")},
        { name: this.$t("generic.no")},
      ],
    }
  },
  mounted() {
    console.log('editing: ', this.editingClient)
    if (this.editingClient) {
      this.name = this.editingClient.name || "";
      this.phoneNumber = this.editingClient.phoneNumber || "";
      this.zipCode = this.editingClient.zipCode || "";
      this.number = this.editingClient.number || "";
      this.street = this.editingClient.street || "";
      this.city = this.editingClient.city || "";
      this.extraInfo = this.editingClient.extraInfo  || "";
      this.box = this.editingClient.box || "";
      this.vat = this.editingClient.vat || "";
      this.email = this.editingClient.email || "";
      this.business = this.choice.find(option =>
          (this.editingClient.business === true && option.name === this.$t("generic.yes")) ||
          (this.editingClient.business === false && option.name === this.$t("generic.no"))
      );
    }else{
      this.business = this.choice.find(option => option.name === this.$t("generic.no"));
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: auto;
  padding: 20px 20px;
}

.dropInput{
  background-color: rgba(129, 137, 201, 0.18);
  padding: 10px 40px 10px 20px;
  padding-right: 20px !important;
  height: 44px !important;
  border-radius: 8px !important;
  color: #efefef
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
}

select {
  padding: 10px 20px 10px 20px;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  row-gap: $default-edge-spacing-l;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-content: stretch;
  column-gap: $default-edge-spacing-l;
  margin-top: $default-edge-spacing-xxl;
  padding-bottom: 70px;

  .button {
    width: 100%;
  }
}

.colorPicker-style {
  padding-right: 10px;
}

.input-hint-color {
  padding: 0 !important;
  font-size: 13px;
  font-weight: normal;
  color: $color-secondary-text;
  opacity: 0.8;
  margin: 0 0 8px 8px;
}

.profile-data-container {
  padding: 0 0 30px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .letter-icon {
    color: $color-primary-text;
    background-color: $color-primary-blue;
    border-radius: 150px;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    padding: 6px;
  }

  .profile-text-container {
    flex-grow: 1;
    padding-left: $default-edge-spacing-l;
    overflow: hidden;

    .title {
      font-size: $font-title;
      color: $color-primary-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .subtitle {
      font-size: $font-subtitle;
      color: $color-secondary-text;
    }
  }

  .delete-button {
    min-width: 18px;
    max-width: 18px;
    margin-right: $default-edge-spacing-xl;
    stroke: $color-pastel-red;

  }


}

.color-picker {
  height: 50px !important;
}

@media (max-width: $screen-md) {

  .button-container {
    justify-content: center;
    flex-direction: column;
    padding-top: $default-edge-spacing;

    .button {
      margin-top: $default-edge-spacing-l;

      &:nth-child(1) {
        padding-right: 0 !important;
      }
    }
  }
}


.custom-check {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  input {
    margin-right: $default-edge-spacing;
  }
}
.dropdown-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-image {
  width: 30px; /* Taille du carré */
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.dropdown-text {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>