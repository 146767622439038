import { createRouter, createWebHistory } from "vue-router";
import CheckAbilities from "@/views/CheckAbilities.vue";
import Dashboard from "@/views/Dashboard.vue";
import LoginPage from "@/views/LoginPage.vue";
import UsersPage from "@/views/UsersPage.vue";
import TablesPage from "@/views/TablesPage.vue";
import ProductsPage from "@/views/ProductsPage.vue";
import ProductGroupsPage from "@/views/ProductGroupsPage.vue";
import MySettingsPage from "@/views/MySettingsPage.vue";
import MyAccountPage from "@/views/MyAccountPage.vue"
import ShiftPage from "@/views/ShiftPage.vue";
import PaymentsPage from "@/views/PaymentsPage.vue"
import OrderProductHistoryPage from "@/views/OrderProductHistoryPage.vue"
import AccountSummaryPage from "@/views/AccountSummaryPage.vue"
import AccountHistoryPage from "@/views/AccountHistoryPage.vue"
import PrintersPage from "@/views/PrintersPage.vue"
import OpenTables from "@/views/OpenTablesPage.vue";
import OpenTakeawayPage from "@/views/OpenTakeawayPage.vue";
import ShiftDetailPage from "@/views/ShiftDetailPage.vue";
import ShiftStatPage from "@/views/ShiftStatPage.vue";
import StockPage from "@/views/StockPage.vue";
import ClientPage from "@/views/ClientPage.vue";
import {JWTHelper} from "@/helpers/JWTHelper";
import InvoicePage from "@/views/InvoicePage.vue";
import WorkerShiftDetailPage from "@/views/WorkerShiftDetailPage.vue";
import KitchenPage from "@/views/KitchenPage.vue";
import ImagePage from "@/views/ImagePage.vue";
import PromoPage from "@/views/PromoPage.vue";


const routes = [
    {
    path: "/",
    name: "/",
    redirect: "/check_abilities",
  },
  {
    path: "/check_abilities",
    name: "CheckAbilities",
    component: CheckAbilities,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/users",
    name: "UsersPage",
    component: UsersPage,
  },
  {
    path: "/tables",
    name: "TablesPage",
    component: TablesPage,
  },
  {
    path: "/products",
    name: "ProductsPage",
    component: ProductsPage,
  },
  {
    path: "/product_groups",
    name: "ProductGroupsPage",
    component: ProductGroupsPage,
  },
  {
    path: "/kitchen",
    name: "Kitchen",
    component: KitchenPage,
  },
  {
    path: "/promo",
    name: "Promo",
    component: PromoPage,
  },
  {
    path: "/images",
    name: "Images",
    component: ImagePage,
  },
  {
    path: "/settings",
    name: "MySettingsPage",
    component: MySettingsPage,
  },
  {
    path: "/me",
    name: "MyAccountPage",
    component: MyAccountPage,
  },
  {
    path: "/analytics/shifts",
    name: "ShiftPage",
    component: ShiftPage,
  },
  {
    path: "/analytics/payments",
    name: "AnalyticsPayments",
    component: PaymentsPage,
  },
  {
    path: "/analytics/orders",
    name: "AnalyticsOrders",
    component: OrderProductHistoryPage,
  },
  {
    path: "/account/:accountId",
    name: "AccountSummary",
    component: AccountSummaryPage,
  },
  {
    path: "/analytics/accounts",
    name: "AccountsHistory",
    component: AccountHistoryPage,
  },
  {
    path: "/printers",
    name: "Printers",
    component: PrintersPage,
  },
  {
    path: "/open-tables",
    name: "OpenTables",
    component: OpenTables,
  },
  {
    path: "/open-takeaway",
    name: "OpenTakeawayPage",
    component: OpenTakeawayPage,
  },
  {
    path: "/shift-details/:shiftId",
    name: "ShiftDetailPage",
    component: ShiftDetailPage,
  },
  {
    path: "/shift-stats/:shiftId",
    name: "ShiftStatPage",
    component: ShiftStatPage,
  },
  {
    path: "/shift-stats/:startdate/:enddate",
    name: "ShiftDetailPageByDate",
    component: ShiftStatPage,
  },
  {
    path: "/worker-shift-detail/:startdate/:enddate",
    name: "WorkerShiftDetailPage",
    component: WorkerShiftDetailPage,
  },
  {
    path: "/stock/:productId",
    name: "stock",
    component: StockPage,
  },
  {
    path: "/clients",
    name: "clients",
    component: ClientPage,
  },
  {
    path: "/invoice",
    name: "invoice",
    component: InvoicePage,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JWTHelper.isLoggedIn();

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  else if (to.path === '/login' && loggedIn){
    return next('/');
  }

  next();
});


export default router;
