<script>
import { mapActions, mapState, mapMutations } from "vuex";
import SideNavItem from "./SideNavItem.vue";
import SideNavHeader from "./SideNavHeader.vue";
import ProfileCard from "./ProfileCard.vue";
import VerticalDivider from "./VerticalDivider.vue";

export default {
  name: "SideNav",
  components: {
    SideNavItem,
    SideNavHeader,
    ProfileCard,
    VerticalDivider,
  },
  methods: {
    ...mapActions("account", {
      logout: "logout",
      getMyData: "getMyData",
    }),
    ...mapMutations("global", ["toggleSideNav", "setExpanded"]),
    goToPage(e) {
      if (this.isPhone()) {
        this.callSetExpandedWithTimeout(false, 400);
      }
      console.log(e.currentTarget.getAttribute("page"));
      this.$router.push(e.currentTarget.getAttribute("page"));
      $(".side-nav-item").removeClass("selected");
      $(e.currentTarget).addClass("selected");
    },
    callSetExpanded(value) {
      this.setExpanded(value);
    },
    callSetExpandedWithTimeout(value, duration) {
      const parentThis = this;
      setTimeout(function () {
        parentThis.setExpanded(value);
      }, duration);
    },
    checkAbilities() {
      ["dashboardLinks", "analyticsLinks", "settingsLinks"].forEach((group) => {
        if (this.currentUser.type !== "ADMIN" && this.currentUser.type !== "SUPER_USER") {
          this[group] = this[group].filter((link) =>
            this.currentUser.abilities.includes(link.id)
          );
        }
      });
    },
  },
  computed: {
    ...mapState("global", ["isSideNavExpanded"]),
    ...mapState("account", {
      currentUser: "user",
    }),
  },
  data() {
    return {
      sideNavWidth: 0,
      sideNavWidthCollapsed: 0,
      dashboardLinks: [
        {
          id: "ANALYTICS_DASHBOARD",
          text: "navBar.dashboard",
          icon: "home.svg",
          page: "/dashboard",
        },
        {
          id: "ADMIN_USERS",
          text: "navBar.users",
          icon: "user.svg",
          page: "/users",
        },
        {
          id: "ADMIN_PRODUCTS",
          text: "navBar.products",
          icon: "shopping-cart.svg",
          page: "/products",
        },
        {
          id: "ADMIN_GROUPS",
          text: "navBar.groups",
          icon: "package.svg",
          page: "/product_groups",
        },
        {
          id: "ADMIN_TABLES",
          text: "navBar.tables",
          icon: "table.svg",
          page: "/tables",
        },
        {
          id: "ADMIN_KITCHEN",
          text: "navBar.kitchen",
          icon: "kitchen.svg",
          page: "/kitchen",
        },
        {
          id: "ADMIN_PROMO",
          text: "navBar.promo",
          icon: "percent.svg",
          page: "/promo",
        },
        {
          id: "ADMIN_PRINTERS",
          text: "navBar.printers",
          icon: "printer.svg",
          page: "/printers",
        },
        {
          id: "ADMIN_CLIENT_DATA",
          text: "navBar.clients",
          icon: "users.svg",
          page: "/clients",
        },
        {
          id: "",
          text: "navBar.invoice",
          icon: "inbox.svg",
          page: "/invoice",
        },
      ],
      analyticsLinks: [
        {
          id: "ANALYTICS_ACCOUNTS",
          text: "navBar.account_history",
          icon: "activity.svg",
          page: "/analytics/accounts",
        },
        {
          id: "ANALYTICS_PAYMENTS",
          text: "navBar.payments",
          icon: "credit-card.svg",
          page: "/analytics/payments",
          hidden: true,
        },
        {
          id: "ANALYTICS_ORDERS",
          text: "navBar.order_history",
          icon: "clock.svg",
          page: "/analytics/orders",
        },
        {
          id: "ANALYTICS_SHIFTS",
          text: "navBar.shift",
          icon: "calculate.svg",
          page: "/analytics/shifts",
        },
        {
          text: "Log",
          icon: "align-left.svg",
          hidden: true,
        },
      ],
      settingsLinks: [
        {
          id: "ACCOUNT_IMAGE",
          text: "imagePageStrings.name",
          icon: "picture.svg",
          page: "/images",
        },
        {
          id: "ACCOUNTS_SETTINGS",
          text: "navBar.settings",
          icon: "settings.svg",
          page: "/settings",
        },
      ],
    };
  },
  mounted() {
    this.checkAbilities();
    const $sideNav = $("#side-nav");
    const parentThis = this;
    $(`.side-nav-item[page='${this.$route.path}']`).addClass("selected");
    this.sideNavWidth = $sideNav.width();
    $sideNav.addClass("collapsed");
    setTimeout(function () {
      parentThis.sideNavWidthCollapsed = $sideNav.width();
      parentThis.callSetExpanded(false);
    }, 350);

    $("#side-nav").click(function (e) {
      setTimeout(function () {
        parentThis.callSetExpanded(true);
      }, 350);
    });

    $("#side-nav").hover(
      function (e) {
        parentThis.callSetExpanded(true);
      },
      function (e) {
        parentThis.setExpanded(false);
      }
    );

    //handle outside sidenav click
    $(document).on("click", function (event) {
      if (
        $(event.target).closest(".menu").length === 0 &&
        $(event.target).closest("#side-nav").length === 0
      ) {
        console.log("Collapsing on document click ", $(event.target).class);
        parentThis.callSetExpanded(false);
      }
    });
  },
  watch: {
    isSideNavExpanded(newState, oldState) {
      const $sideNav = $("#side-nav");
      const $content = $(".main-router");
      if (newState) {
        $sideNav.removeClass("collapsed");
        $sideNav.css("width", this.sideNavWidth + "px");
        if ($(window).width() < 768) {
          $content.css({ opacity: 0.3 });
        }
      } else {
        $sideNav.addClass("collapsed");
        $sideNav.css("width", this.sideNavWidthCollapsed + "px");
        $content.css({ opacity: 1 });
      }
    },
  },
};
</script>

<template>
  <div>
    <profile-card
      img="logo.svg"
      :title="currentUser.organization.name.length > 10 ? currentUser.organization.name.slice(0, 10) + '...' : currentUser.organization.name"
      :subtitile="currentUser.organization.orgId"
      :manageable-orgs="currentUser.managedOrganizations"
    />
    <vertical-divider />
    <div class="side-nav-container">
      <side-nav-header :text="$t('navBar.dashboard')" />
      <side-nav-item
        v-for="dashboardLink in dashboardLinks"
        :key="dashboardLink.page"
        @click="goToPage"
        :text="$t(dashboardLink.text)"
        :icon="dashboardLink.icon"
        :page="dashboardLink.page"
        :hidden="dashboardLink.hidden"
      />
    </div>
    <vertical-divider v-if="analyticsLinks.length > 0" />
    <div class="side-nav-container" v-if="analyticsLinks.length > 0">
      <side-nav-header
        :text="$t('navBar.analytics')"
        v-if="analyticsLinks.length > 0"
      />
      <side-nav-item
        v-for="analyticsLink in analyticsLinks"
        :key="analyticsLink.page"
        @click="goToPage"
        :text="$t(analyticsLink.text)"
        :icon="analyticsLink.icon"
        :page="analyticsLink.page"
        :hidden="analyticsLink.hidden"
      />
    </div>
    <vertical-divider v-if="settingsLinks.length > 0" />
    <div class="side-nav-container" v-if="settingsLinks.length > 0">
      <side-nav-header
        :text="$t('navBar.account')"
        v-if="settingsLinks.length > 0"
      />
      <!-- <side-nav-item text="My Data" icon="user.svg"/> -->

      <side-nav-item
        v-for="settingsLink in settingsLinks"
        :key="settingsLink.page"
        @click="goToPage"
        :text="$t(settingsLink.text)"
        :icon="settingsLink.icon"
        :page="settingsLink.page"
        :hidden="settingsLink.hidden"
      />
    </div>
    <div class="side-nav-container bottom">
      <vertical-divider />
      <side-nav-item
        @click="logout()"
        :text="$t('navBar.logout')"
        icon="log-out.svg"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bottom {
  margin-top: auto !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #24262e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>