<template>
  <div>
    <popup-view :isVisible="promoState.editingPromo || promoState.isAddingPromo"
                @close="setEditingPromo(null); setIsAddingPromo(false)"
    >
      <edit-promo-dialog/>
    </popup-view>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                             @cancelDeleteAction="deleteOpenDialog = false"/>
    </popup-view>

    <page-header
        class="header"
        @actionClick="setIsAddingPromo(true)"
        iconName="percent.svg"
        actionIconName="plus.svg"
        :actionText="$t('promoPageStrings.addPromo')"
        :headerTitle="$t('generic.promo')"
    />

    <DataTable
        :value="promoState.promo"
        v-model:filters="filters"
        filter-display="row"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['promoCode', 'discountAmount','usedCount']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
            </span>
        </div>
      </template>
      <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <!-- <Column field="promoCodeId" header="promoCodeId" sortable></Column> -->
      <Column field="promoCode" header="Promo code" sortable></Column>
      <Column field="discountAmount" header="Amount" sortable>
        <template #body="{ data }">
            <span class="vat-out">€ {{
                data.discountAmount
              }}</span>
        </template>
      </Column>
      <Column field="startDate" header="Start Date" sortable>
        <template #body="{ data }">
          {{ data.startDate.toDate()}} <br>
          <span class="default-text">{{ data.startDate.toTime()}}</span>
        </template>
      </Column>
      <Column field="endDate" header="End Date" sortable>
        <template #body="{ data }">
          {{ data.endDate.toDate()}} <br>
          <span class="default-text">{{ data.endDate.toTime()}}</span>
        </template>
      </Column>
      <Column field="maxUses" header="Max Uses" sortable></Column>
      <Column field="usedCount" header="Used" sortable></Column>
      <Column field="quantity" header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button @click="setEditingPromo(data)">
              <inline-svg :src="require('@/assets/icons/edit.svg')"/>
            </button>
            <button class="delete" @click="openDeleteDialog(data)">
              <inline-svg :src="require('@/assets/icons/trash.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from './components/PageHeader.vue';
import PopupView from './components/PopupView.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import {isNumber} from "chart.js/helpers";
import EditPromoDialog from "@/views/dialogs/EditPromoDialog.vue";

export default {
  name: "PromoPage",
  components: {
    EditPromoDialog,
    DataTable,
    Column,
    InputText,
    PopupView,
    PageHeader,
    ConfirmDeleteDialog
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        printer: {value: null, matchMode: FilterMatchMode.STARTS_WITH}
      }),
      deleteOpenDialog: false,
      currentSelect: {}
    }
  },
  computed: {
    ...mapState({
      promoState: 'promo'
    })
  },
  methods: {
    ...mapActions('promo', ['getPromo', 'deletePromo','updatePromo']),
    ...mapMutations('promo', ['setIsAddingPromo', 'setEditingPromo']),
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    submitDelete() {
      this.deletePromo(this.currentSelect);
      this.deleteOpenDialog = false;
    }
  },
  mounted() {
    this.getPromo();
  }
}
</script>

<style lang="scss" scoped>
.edit-row-btn {
  column-gap: 10px;
  background: none !important;
}

.row-icon {
  width: 18px;
  height: 18px;

  &.disabled {
    color: $color-secondary-text !important;
  }
}
</style>