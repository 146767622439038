import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const promoService = {
    getPromo,
    updatePromo,
    addPromo,
    deletePromo
}

function getPromo() {
    return apiClient.get('/promo', {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function updatePromo(promo) {
    return apiClient.patch(`/promo/${promo.promoCodeId}`, promo, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function addPromo(promo) {
    return apiClient.post(`/promo`, promo, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}

function deletePromo(promo) {
    return apiClient.delete(`/promo/${promo.promoCodeId}`, {headers: authHeader()})
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));  
}