<template>
  <div>
    <popup-view :isVisible="clientState.editingClient || clientState.isAddingClient"
                @close="setEditingClient(null); setIsAddingClient(false)"
    >
      <edit-client-dialog/>
    </popup-view>
    <page-header
        class="header"
        @actionClick="setIsAddingClient(true)"
        iconName="users.svg"
        actionIconName="plus.svg"
        :actionText="$t('clientStrings.addClient')"
        :headerTitle="$t('generic.clients')"
    />

    <DataTable
        :value="clientState.clientData"
        v-model:filters="filters"
        filter-display="row"
        paginator
        ref="dtclient"
        :rows="500"
        :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['name','phoneNumber','street','city','extraInfo']"
    >
      <template #header>
        <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                <Button style="margin-left: 3px;" label="Export" @click="exportCSV($refs)" >
                  <inline-svg class="action-icon" :src="require('@/assets/icons/download.svg')" />
                </Button>

            </span>
        </div>
      </template>
      <template #empty> {{$t('productGroupPage.noFundResultTable')}} </template>
      <template #loading> {{$t('productGroupPage.loadingResultTable')}} </template>
      <Column field="name" header="Name" sortable></Column>
      <Column field="phoneNumber" header="Phone" sortable></Column>
      <Column field="email" header="email" ></Column>
      <Column field="street" header="Street" sortable>
        <template #body="{ data }">
          {{ data.street }} {{ data.number }} {{ data.box }} <br>
        </template>
      </Column>
      <Column field="number" header="number" hidden></Column>
      <Column field="box" header="box" hidden></Column>
      <Column field="zipCode" header="zipCode" hidden></Column>
      <Column field="city" header="City" sortable>
        <template #body="{ data }">
          {{ data.zipCode }} {{ data.city }} <br>
        </template>
      </Column>
      <Column field="vat" header="vat" sortable></Column>
      <Column field="business" header="business" sortable></Column>
      <Column field="extraInfo" header="Extra Info" sortable></Column>
      <Column field="quantity" header="Actions">
        <template #body="{data}">
          <div class="edit-row-btn">
            <button @click="setEditingClient(data)">
              <inline-svg :src="require('@/assets/icons/edit.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { ref } from 'vue';
import {mapActions, mapMutations, mapState} from "vuex";
import { FilterMatchMode } from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import EditClientDialog from "@/views/dialogs/EditClientDialog.vue";
import PopupView from "@/views/components/PopupView.vue";

export default {
  name: "ClientPage",
  components: {
    PopupView, EditClientDialog,
    DataTable,
    Column,
    InputText,
    PageHeader
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'productGroup.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }),
      selectedFilterProduct: "",
      itemFiltered:[],
      currentSelect: {}
    }
  },
  computed: {
    ...mapState({
      clientState: 'client'
    })
  },
  methods: {
    ...mapActions('client', ['getClientData', "updateClient"]),
    ...mapMutations('client', ['setIsAddingClient', 'setEditingClient']),

  exportCSV() {
    this.$refs.dtclient.exportCSV();
  }},
  mounted() {
    this.getClientData();
  }
}
</script>

<style>

</style>